import axios from 'axios';
import { URL_INCIDENCIAS } from '../../../constants/url';


export const useGetIncidenciasXls = () => {

    const  downloadFile = () => {
        axios({
          url: `${ URL_INCIDENCIAS }/GetXlsIncidenciasPT2`,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([ response.data ]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'xlsIncidenciasPT.xlsx');
          document.body.appendChild(link);
          link.click();
        });
      }

      return {downloadFile}
      

 
}

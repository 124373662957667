import { EyeFilled } from "@ant-design/icons";
import { EstatusPT_Pill } from "../../../components/estatusPill/EstatusPT_Pill";
import { cortaString } from "../../../utils/cortaString";
import { dateFromString } from "../../../utils/dateFromString";
import { daysFromToday } from "../../../utils/daysFromToday";
import { Tache } from "../../serviciosConsesionarios/consesionariosServicios/_Tache";
import { useNavigate } from "react-router";
import { Tooltip } from "antd";

////
export default function useColums() {
  const navigate = useNavigate();
  const colums = [
    {
      title: "Folio",
      dataIndex: "idUnico",
      filters: [
        { text: "PT", value: "PT" },
        { text: "GM", value: "GM" },
        { text: "PTC", value: "PTC" },
        { text: "NISS", value: "NISS" },
      ],
      filterSearch: true,
      render: (value, row) => value ?? "N/A",
      onFilter: (value, record) =>
        record?.idUnico === null || record?.idUnico.includes(value),
    },

    {
      title: "Robo_ID",
      key: "roboID",
      dataIndex: "roboID",
    },
    // {
    //   title: "fecha Registro",
    //   dataIndex: "fechaRegistro",
    // },
    // {
    //   title: "Fecha Siniestro",
    //   dataIndex: "fechaSiniestro",
    // },
    {
      title: "Fecha",
      dataIndex: "fechaInicio",
      render: (dato, row) => dateFromString(dato).soloFecha,
    },
    {
      title: "Transcurrido",
      render: (d, row) => {
        if (d.estatusId === 7)
          return <div style={{ textAlign: "center" }}>-</div>;
        return (
          <div style={{ textAlign: "center" }}>
            {daysFromToday(d.fechaInicio)}
          </div>
        );
      },
    },
    {
      title: "Siniestro",
      dataIndex: "numSiniestro",
    },
    {
      title: "Póliza",
      dataIndex: "numPoliza",
    },

    {
      title: "Asegurado",
      dataIndex: "nombreAsegurado",
      render: (val, row) => (
        <Tooltip title={val}>{cortaString(val, 25)}</Tooltip>
      ),
    },
    {
      title: "Refacturación",
      dataIndex: "refacturacion",
      render: (dato, renglon) => <Tache dato={dato} />,
    },
    {
      title: "Documentos",
      dataIndex: "totalDocumentos",
      render: (dato, renglon) => (
        <div style={{ textAlign: "center" }}>
          <div className="circulo">{dato}</div>
        </div>
      ),
    },
    {
      title: "Guia",
      dataIndex: "guia",

      render: (val, row) => (
        <Tooltip title={val}>{cortaString(val, 25)}</Tooltip>
      ),
    },
    {
      title: "Incidencias",
      render: (data, row) => (
        <div style={{ textAlign: "center" }}>
          {" "}
          {data.incidenciasAbiertas}/{data.incidenciasTotales}
        </div>
      ),
    },
    {
      title: "Estatus",
      render: (dato, renglon) => <EstatusPT_Pill renglon={renglon} />,
    },
    {
      render: (dato, renglo) => (
        <EyeFilled
          onClick={() => navigate(`/PTDetalle/${dato.id}`)}
          className="botonIcono"
        />
      ),
    },
  ];

  return { colums };
}
